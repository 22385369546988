import React, { useState, useCallback } from "react";

const SERVICES = {
  NETFLIX: {
    name: "NETFLIX",
    description: "Recupera tu código de acceso temporal de Netflix",
    icon: "🎬",
    color: "bg-red-600",
    key: "netflix",
    url: "https://code-front-monarca-309cfe529bb8.herokuapp.com/solisesion.php",
  },
  DISNEY: {
    name: "DISNEY+",
    description: "Obtén tu código de acceso único para Disney+",
    icon: "🏰",
    color: "bg-blue-600",
    key: "disney",
    url: "https://code-front-monarca-309cfe529bb8.herokuapp.com/soli.php",
  },
};

const EmailSearch = () => {
  const [emailInput, setEmailInput] = useState("");
  const [selectedService, setSelectedService] = useState(SERVICES.NETFLIX);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [codeResult, setCodeResult] = useState({
    message: "",
    otp: "",
    date: "",
  });

  const searchCode = useCallback(async () => {
    if (!emailInput || !selectedService) return;
    setIsLoading(true);
    setCodeResult({
      message: "",
      otp: "",
      date: "",
    });
    try {
      const url = `${selectedService.url}?recipient=${emailInput}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const html = await response.text();
      const parsedJson = JSON.parse(html);

      if (parsedJson.otp) {
        setCodeResult({
          message: "Código encontrado:",
          otp: parsedJson.otp,
          date: parsedJson.date,
        });
      } else {
        setCodeResult({
          message: "No se encontró el código.",
          otp: "",
          date: "",
        });
      }
    } catch (error) {
      console.error("Error al buscar el código:", error);
      setCodeResult({
        message: "Ocurrió un error al buscar el código.",
        otp: "",
        date: "",
      });
    }
    setIsLoading(false);
  }, [emailInput, selectedService]);

  const copyOtpToClipboard = useCallback(() => {
    if (codeResult.otp) {
      navigator.clipboard
        .writeText(codeResult.otp)
        .then(() => {
          setShowNotification(true);
          setTimeout(() => setShowNotification(false), 2000);
        })
        .catch((err) => console.error("Error al copiar: ", err));
    }
  }, [codeResult.otp]);

  return (
    <div className="bg-gray-900 text-gray-100 p-4 sm:p-8 min-h-screen">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
        Consulta de Correo Electrónico
      </h1>
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
          {Object.values(SERVICES).map((service) => (
            <div
              key={service.key}
              className={`mb-4 p-4 rounded-lg cursor-pointer transition-colors ${
                selectedService.key === service.key
                  ? service.color
                  : "bg-gray-800"
              } hover:bg-opacity-80`}
              onClick={() => setSelectedService(service)}
            >
              <div className="flex items-center">
                <span className="text-2xl mr-3">{service.icon}</span>
                <div>
                  <h3 className="font-bold">{service.name}</h3>
                  <p className="text-sm text-gray-400">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full lg:w-2/3 bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4">
            {selectedService.name} - Consulta de Correo Electrónico
          </h2>
          <div className="mb-4">
            <label className="block mb-2">Correo Electrónico:</label>
            <input
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-white"
              placeholder="correo@tudominio.com"
              disabled={isLoading}
            />
          </div>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <button
              onClick={searchCode}
              disabled={isLoading || !emailInput}
              className={`${selectedService.color} hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded transition-all duration-300 transform hover:scale-105 disabled:opacity-50 disabled:transform-none`}
            >
              {isLoading ? "Buscando..." : "Buscar Código"}
            </button>
          </div>

          {codeResult.message && (
            <div className="mt-6 p-4 bg-gray-700 rounded-lg">
              <p className="text-lg font-semibold mb-2">{codeResult.message}</p>
              {codeResult.otp && (
                <div className="mt-2">
                  <div className="mb-2">
                    <span className="text-green-400 font-bold">Código: </span>
                    <span className="text-blue-300">{codeResult.otp}</span>
                  </div>
                  <div>
                    <span className="text-green-400 font-bold">Fecha: </span>
                    <span className="text-blue-300">{codeResult.date}</span>
                  </div>
                  <button
                    onClick={copyOtpToClipboard}
                    className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-all duration-300 transform hover:scale-105"
                  >
                    Copiar Código
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}

      <div
        className={`fixed bottom-4 right-4 bg-green-500 text-white px-3 py-2 sm:px-4 sm:py-2 rounded transition-opacity duration-300 text-sm sm:text-base ${
          showNotification ? "opacity-100" : "opacity-0"
        }`}
      >
        Código copiado al portapapeles
      </div>
    </div>
  );
};

export default EmailSearch;
